import CreatorsLabBg from "../../assets/creators-lab-cover.png"
import CreatorsLabLogo from "../../assets/svg/creatorslab-logo.svg"
import CreatorsLabTextLogo from "../../assets/svg/creator-lab-logo-text.svg"

import ComingSoon from "../../assets/svg/coming-soon.svg"
import Replacement from "../../assets/svg/replacement.svg"
import SubtleBg from "../../assets/svg/creators-lab-bg.svg"

import ArtisanAlliance from "../../assets/creators-lab-img-1.png"
import CreatorsMarketplace from "../../assets/creators-lab-img-2.png"
import ArNftStudio from "../../assets/creators-lab-img-3.png"
import CtaSecImg1 from "../../assets/creators-lab-img-4.png"
import CtaSecImg2 from "../../assets/creators-lab-img-5.png"

import TwitterIcon from "../../assets/svg/x-twitter-icon.svg"
import TelegramIcon from "../../assets/svg/telegram-icon.svg"

import "./styles.scss"
import { Mixpanel } from "../../utils/mixpanel"
import {
  LP_CreatorsLab_GetInTouch1,
  LP_CreatorsLab_GetInTouch2,
  LP_NFTCollection_JoinWaitlist
} from "../../utils/mixpanel/mixpanelActionTypes"

function CreatorLabs() {
  return (
    <div className="lp-creators-lab">
      <div className="lp-hero-creators-lab-hero">
        <img src={CreatorsLabBg} className="lp-creators-lab-img" alt="lp-creators-lab-img" />

        <div className="lp-hero-creators-lab-container">
          <div className="lp-hero-creators-lab-content">
            <div className="creators-lab-logo-container">
              <div className="coming-soon-wrapper">
                <img src={CreatorsLabLogo} className="lp-creators-lab-logo" alt="lp-creators-lab-logo" />
                <img src={ComingSoon} className="lp-coming-soon-text" alt="lp-coming-soon-text" />
              </div>
              <img src={CreatorsLabTextLogo} className="lp-creators-lab-text" alt="lp-creators-lab-logo" />
            </div>
            <p id="lp-description-text">
              Dive into the future with Creator's Lab, where your art transforms into interactive AR NFTs in the Web3
              space. This soon-to-launch platform is your gateway to blending creativity with AR NFT, making the new
              asset class an immersive experience. Get ready for a world where your digital art breaks new ground.
            </p>

            <p id="lp-description-text">
              Welcome to the future of creation. <span> Welcome to Creator's Lab. </span>
            </p>

            <div className="lp-social-interaction-container">
              <p id="lp-description-text-special">Become part of the Creator Community</p>
              <div className="lp-social-interaction-icons">
                <a onClick={()=>{
                  Mixpanel.track(LP_CreatorsLab_GetInTouch1)}} href="https://bsu5ua93efj.typeform.com/to/CqTXTybp" target="_blank" className="icon-btn">
                  {/* <img src={TwitterIcon} className="icon-btn" alt="lp-social-twitter" /> */}
                  Get in touch
                </a>
                {/* <a href="https://t.me/torum_official_group_en" target="_blank">
                  <img src={TelegramIcon} className="icon-btn" alt="lp-social-telegram" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lp-creators-lab-body">
        <p id="lp-creators-lab-big-title">
          <span>WHAT’S IN IT</span> FOR EVERYONE?
        </p>
        <div className="lp-creators-lab-container reverse">
          <div className="lp-creators-lab-card">
            <p id="lp-creators-lab-info-title">ARtisan Alliance</p>
            <p id="lp-creators-lab-info-description">
              Calling all NFT collection owners! This is your gateway to unlocking the full potential of your NFTs.
            </p>
            <p id="lp-creators-lab-info-description">
              The ARtisan Alliance connects you with top-tier 3D artists and AR developers. With the power of AR
              integration, we’ll elevate your collections into the future of digital art by transforming static NFTs
              into dynamic NFT AR Face Filters—the new asset class that enriches the collector experience, enhances
              marketability, and accelerates the growth of your very own community.
            </p>
          </div>
          <img src={ArtisanAlliance} className="lp-creators-lab-info-img" alt="replacement" />
        </div>

        <div className="lp-creators-lab-container">
          <img src={CreatorsMarketplace} className="lp-creators-lab-info-img" alt="replacement" />
          <div className="lp-creators-lab-card">
            <p id="lp-creators-lab-info-title">Creators Marketplace</p>
            <p id="lp-creators-lab-info-description">
              Made for NFT artists who are leading the charge into the future of digital assets, the Creator Marketplace
              makes your NFT AR Face Filters accessible to all, propelling your creations into a whole new asset class.
            </p>
            <p id="lp-creators-lab-info-description">
              Here, you have the canvas to showcase your creative masterpieces! Whether you’re crafting unique AR NFTs
              available to a select few (ERC-1155), or building entire collections (ERC-721) that embody your artistic
              vision, our marketplace acts as the ultimate distribution platform, bringing your creations to a community
              eager to express and redefine their digital identities on Torum. 
            </p>
          </div>
        </div>

        <div className="lp-creators-lab-container reverse">
          <div className="lp-creators-lab-card">
            <p id="lp-creators-lab-info-title">AR NFT Studio</p>
            <p id="lp-creators-lab-info-description">
              Bring your visions to life with the AR NFT Studio—our user-friendly toolkit designed to push the limits of
              your creativity.
            </p>
            <p id="lp-creators-lab-info-description">
              Equipped to translate your ideas into interactive and immersive experiences, our studio features intuitive
              tools that will help you transform traditional artwork into 3D AR masterpieces. Whether you're a seasoned
              artist or a digital enthusiast exploring new mediums, the AR NFT Studio was made for artists of all
              levels.
            </p>
          </div>
          <img src={ArNftStudio} className="lp-creators-lab-info-img" alt="replacement" />
        </div>

        <div className="lp-creators-lab-cta">
          <img src={CtaSecImg1} className="cta-img-1" alt="CtaSecImg1" />

          <p className="lp-creators-lab-cta-title">Join the Creator Community</p>
          <p className="lp-creators-lab-cta-desc">
            Let’s create a new digital future and transform the NFT landscape—together.
          </p>
          <a
            href="
https://bsu5ua93efj.typeform.com/to/CqTXTybp"
            target="_blank"
            className="icon-btn"
            onClick={()=>{
              Mixpanel.track(LP_CreatorsLab_GetInTouch2)}}
          >
            Get in touch
          </a>

          <img src={CtaSecImg2} className="cta-img-2" alt="CtaSecImg2" />
        </div>

        <img src={SubtleBg} className="lp-creators-lab-sub-bg" alt="subtle-bg" />
      </div>
      <div className="dark-bg-cl"></div>
    </div>
  )
}

export default CreatorLabs
