import React, { useEffect, useState } from "react"
import { toast as toastify } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { useAccount, useReadContract, useSignMessage, useWaitForTransactionReceipt, useWriteContract } from "wagmi"
import { formatEther } from "viem"

import "./Journey.scss"

// assets
import Chubby1 from "../../../assets/chubby_Static_Carousel_1.png"
import Chubby2 from "../../../assets/chubby_Static_Carousel_2.png"
import Chubby3 from "../../../assets/chubby_Static_Carousel_3.png"
import Chubby4 from "../../../assets/chubby_Static_Carousel_4.png"
import Chubby5 from "../../../assets/chubby_Static_Carousel_5.png"

import { ReactComponent as CloseIcon } from "../../../assets/svg/x-icon.svg"
import { ReactComponent as CheckGradientIcon } from "../../../assets/svg/check-gradient.svg"

// constants
import { COLLECTIONS } from "../../../constants"
import CHUBBY_CUBS_ABI from "../../../contracts/CHUBBY_CUBS_ABI.json"
import { CHUBBY_CUBS_CONTRACT_ADDRESS } from "../../../web3/tokens"

import {
  AVATAR_SCENARIO_STEPS,
  ELIGIBLE_FOR_FILTER_ONLY_SCENARIO_STEPS,
  LEGACY_SCENARIO_STEPS,
  NFT_TRAITS_STATUS,
  PUBLIC_SCENARIO_STEPS,
  SCENARIO_TYPE,
  changeStep,
  checkEligibility,
  getLockedNFTs,
  getNFTMetadataByTokenId,
  getNFTMetadataByTraitId,
  registerUser,
  resetAvatarScenarioState,
  rollAll,
  rollFinalize,
  setMintTxHash,
  setMintedTokenId,
  setNFTDetailsModalStatus,
  setSelectedNFT,
  setSignature,
  watchEligibilityApi,
} from "../../../store/slices/userSlice"

import useCountdown from "../../../hooks/useCountdown"
import useWindowSize from "../../../hooks/useWindowResize"

import { getSignatureMessage } from "../../../utils/signature"
import { getOwnedTokenIdListFromContract } from "../../../utils/getContractData"
import { getUnassignedNFTAssetURLByImageName, getUnassignedNFTAssetURLByTraitId } from "../../../utils/getAssetURL"
import { checkAndSwitchToSupportedNetwork } from "../../../web3/config"

// components
import toast from "../../../global/Toast/Toast"
import Modal from "../../../components/Modal/Modal"
import Button from "../../../components/Button/Button"
import NFTDetails from "../../../components/NFTDetails/NFTDetails"
import Eligibility from "../../../components/Eligibility/Eligibility"
import { RaffleRules, RollOptions } from "../../../components/Raffle/Raffle"
import ActivatePersona from "../../../components/ActivatePersona/ActivatePersona"
import { TransactionFailed } from "../../../components/Burn/Burn"
import { MintButton, PendingButton } from "../../../components/Buttons/Buttons"
import SignYourWallet from "../../../components/SignYourWallet/SignYourWallet"
import { TransactionPending, TransactionRejected } from "../../../components/Transaction/Transaction"
import OfferHelp from "../../../components/OfferHelp/OfferHelp"
import NFTCarousel from "../../../components/NFTCarousel/NFTCarousel"

const UI_TEXT = {
  [SCENARIO_TYPE.AVATAR_WHITELIST]: {
    title: `Your One-of-a-Kind Chubby Cub Awaits`,
    subtitle: `Roll the dice to give your Chubby Cub its unique traits! Choose carefully as your selection is final once you complete the minting process.`,
  },
  [SCENARIO_TYPE.LEGACY_WHITELIST]: {
    title: `Yay! <span>Mint</span> Your Chubby Cub for FREE`,
    subtitle: `Here’s a gift to thank you for being a part of our community.`,
  },
  [SCENARIO_TYPE.PUBLIC]: {
    title: `Adopt Your Very Own Chubby Cub`,
    subtitle: `Enjoy exclusive access to Torum V2 when you mint a Chubby Cub NFT! <br/>
    Limited supply available-mint yours now.`,
  },
  [SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY]: {
    title: `Oh, Look! What’s That In Your Wallet?`,
    subtitle: `Great news, it appears you own an NFT that unlocks its very own AR Filter! Activate and try it on now to let your digital adventure begin. <br/> 
    Welcome to the club!`,
  },
}

const MINTED_NFT_COUNT_POLLING_INTERVAL = 30000 // ms
const TX_PENDING_SLEEP_DURATION = 60 // seconds

function sleepFor(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds)
  })
}

/**
 * Note: Read Documentation.md to know how this works!
 * */
function Journey() {
  const dispatch = useDispatch()
  const account = useAccount()

  const { signMessageAsync } = useSignMessage()

  const { data: mintPrice } = useReadContract({
    abi: CHUBBY_CUBS_ABI,
    address: CHUBBY_CUBS_CONTRACT_ADDRESS,
    functionName: "PRICE",
  })

  const {
    data: hash,
    writeContract,
    isPending: isMintOngoing,
    error: mintError,
    isError: isTxRejected,
  } = useWriteContract()

  const { isLoading: isConfirmingTx, isSuccess: isConfirmedTx } = useWaitForTransactionReceipt({
    hash,
  })

  const scenario = useSelector((state) => state.user.scenario)
  const step = useSelector((state) => state.user.step)
  const lockType = useSelector((state) => state.user.lockType)
  const nfts = useSelector((state) => state.user.eligibility?.nfts)
  const selectedNFT = useSelector((state) => state.user.selectedNFT)
  const isNFTDetailsModalOpen = useSelector((state) => state.user.isNFTDetailsModalOpen)
  const isRollFinalizeOngoing = useSelector((state) => state.user.isRollFinalizeOngoing)

  const [isSignatureOngoing, setSignatureOngoing] = useState(false)
  const [isSleeping, setIsSleeping] = useState(false)

  function shouldShowCommunityNFTs() {
    if (nfts?.length > 0) {
      if (scenario === SCENARIO_TYPE.AVATAR_WHITELIST) {
        if (
          [
            "",
            AVATAR_SCENARIO_STEPS.START_ROLLING,
            AVATAR_SCENARIO_STEPS.RAFFLE_RULES,
            AVATAR_SCENARIO_STEPS.RAFFLE_OPTIONS,
          ].includes(step)
        ) {
          return true
        }
      }

      if (
        [SCENARIO_TYPE.LEGACY_WHITELIST, SCENARIO_TYPE.PUBLIC, SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY].includes(
          scenario
        )
      ) {
        if (step !== LEGACY_SCENARIO_STEPS.MINT_SUCCESS) {
          return true
        }
      }
    }
    return false
  }

  // this ensures the button state remains disabled until we're sure the mint is completed and user is ready to be navigated to final ui
  const isTxOngoing = isSignatureOngoing || isRollFinalizeOngoing || isMintOngoing || isConfirmingTx || isSleeping

  function getFreshMintedNFTForNFTCarousel() {
    const payloadForEligibilityCheck = {
      address: account.address?.toLowerCase(),
      collectionId: "",
    }

    dispatch(checkEligibility(payloadForEligibilityCheck))
  }

  useEffect(() => {
    if (isTxRejected) {
      if (mintError) {
        console.error(mintError?.shortMessage)
      }

      toastify.dismiss()

      toast({
        type: "error",
        text: <TransactionRejected />,
        // customConfig: {
        //   autoClose: false,
        // },
      })
    }

    if (isConfirmingTx) {
      toast({
        type: "info",
        text: <TransactionPending />,
        customConfig: {
          autoClose: false,
          // toastId: "tx-pending-toast",
        },
      })
    }

    if (isConfirmedTx) {
      async function fetchContractData() {
        try {
          setIsSleeping(true)

          await dispatch(
            watchEligibilityApi({
              address: account.address,
              interval: 3000,
              prevLength: nfts?.length,
            })
          )

          dispatch(setMintTxHash(hash))

          const maxRetries = 10
          const retryDelay = 3000 // ms

          const ownedTokenIdList = await getOwnedTokenIdListFromContract(account.address, maxRetries, retryDelay)

          // TODO: REMOVE AFTER DISABLING CONTRACT CONDITIONS OF MINT LIMIT
          // Temporarily added for QA. The most recently minted token will be at the last index.
          // The newly minted token will be seen in the final screen.
          const tokenId = String(ownedTokenIdList?.[ownedTokenIdList?.length - 1])

          // TODO: ENABLE THIS AFTER REMOVING ABOVE.
          // const tokenId = String(ownedTokenIdList?.[0])

          dispatch(setMintedTokenId(tokenId))

          if (process.env.REACT_APP_ENV !== "production") {
            console.log(ownedTokenIdList, "ownedTokenIdList")
          }

          if (scenario === SCENARIO_TYPE.AVATAR_WHITELIST) {
            if (isNFTDetailsModalOpen) {
              dispatch(setNFTDetailsModalStatus(false))
            }
            dispatch(getNFTMetadataByTokenId({ tokenId }))

            toastify.dismiss()

            dispatch(changeStep(AVATAR_SCENARIO_STEPS.MINT_SUCCESS))

            setIsSleeping(false)

            // called to receive the newly minted nft in nfts array inside eligibility api response
            getFreshMintedNFTForNFTCarousel()

            /**
             * Note: Some state values like lockedNFTs, lockedUntil and lockType are used to
             * navigate the user to final screen after minting.
             *
             * Edge case: After reaching final screen, we have to remove these state values so that
             * the user stays on the final screen and to avoid taking actions which has already been taken once during the user journey.
             * So, as soon as user journey is done and user is navigated to final screen, I remove few of the state values which are not needed by the app.
             */
            dispatch(resetAvatarScenarioState())

            return
          }

          // called to receive the newly minted nft in nfts array inside eligibility api response
          getFreshMintedNFTForNFTCarousel()
          // for legacy and public, we fetch NFT metadata before navigating user to final screen
          dispatch(getNFTMetadataByTokenId({ tokenId }))

          toastify.dismiss()

          if (scenario === SCENARIO_TYPE.LEGACY_WHITELIST) {
            dispatch(changeStep(LEGACY_SCENARIO_STEPS.MINT_SUCCESS))
          }

          if (scenario === SCENARIO_TYPE.PUBLIC) {
            dispatch(changeStep(PUBLIC_SCENARIO_STEPS.MINT_SUCCESS))
          }
        } catch (error) {
          console.error(error, "error in fetchContractData in Journey")
        }
      }

      fetchContractData()
    }
  }, [isConfirmingTx, isConfirmedTx, isTxRejected])

  async function onFreeMint() {
    try {
      await checkAndSwitchToSupportedNetwork()

      setSignatureOngoing(true)

      const signature = await signMessageAsync({ message: getSignatureMessage(account.address?.toLowerCase()) })

      setSignatureOngoing(false)

      dispatch(setSignature(signature))

      // for avatar, we call register api on roll button click
      if (scenario !== SCENARIO_TYPE.AVATAR_WHITELIST) {
        const payload = {
          address: account.address,
          signature,
        }

        await dispatch(registerUser(payload))
      }

      if (scenario === SCENARIO_TYPE.AVATAR_WHITELIST) {
        console.log("lockType is => ", lockType)
        /**
         * Note: If the user is minting a NFT while the timer is active, its status will be 0 ( Available )
         * If the user is minting a NFT after the timer has run out, its status will be 1 ( Time-Locked )
         * If the user selected a NFT within time countdown and finalized it, its status will be 2 ( User-Locked )
         */
        if (lockType !== "user-locked") {
          setSignatureOngoing(true)

          const signature = await signMessageAsync({
            message: `Allow Torum to activate this NFT for you to claim. Please sign to let us verify that you are the owner of this address ${account.address?.toLowerCase()}`,
          })

          setSignatureOngoing(false)

          const rollFinalizePayload = {
            address: account.address,
            traitId: selectedNFT.traitId,
            signature: signature,
          }

          try {
            const response = await dispatch(rollFinalize(rollFinalizePayload))

            if (response.payload.success) {
              writeContract({
                address: CHUBBY_CUBS_CONTRACT_ADDRESS,
                abi: CHUBBY_CUBS_ABI,
                functionName: "claim",
              })
            }
          } catch (error) {
            console.error(error, "error on onFreeMint")
          }
          return
        }

        writeContract({
          address: CHUBBY_CUBS_CONTRACT_ADDRESS,
          abi: CHUBBY_CUBS_ABI,
          functionName: "claim",
        })
        return
      }

      /**
       * Except Avatar user-locked flow, this is called for all other Avatar edge-cases as well as other journeys
       */
      writeContract({
        address: CHUBBY_CUBS_CONTRACT_ADDRESS,
        abi: CHUBBY_CUBS_ABI,
        functionName: "claim",
      })
    } catch (error) {
      setSignatureOngoing(false)
      console.error(error, "error in onFreeMint")
    }
  }

  async function onPaidMint() {
    // called for public users
    try {
      await checkAndSwitchToSupportedNetwork()

      setSignatureOngoing(true)

      const signature = await signMessageAsync({ message: getSignatureMessage(account.address?.toLowerCase()) })

      setSignatureOngoing(false)

      dispatch(setSignature(signature))

      const payload = {
        address: account.address,
        signature,
      }

      await dispatch(registerUser(payload))

      writeContract({
        address: CHUBBY_CUBS_CONTRACT_ADDRESS,
        abi: CHUBBY_CUBS_ABI,
        functionName: "mint",
        value: String(mintPrice),
      })
    } catch (error) {
      setSignatureOngoing(false)
      console.error(error, "error in onPaidMint")
    }
  }

  function shouldOpenSignYourWalletModal() {
    if (isConfirmingTx) return false
    if (isMintOngoing) return true
  }

  function onNFTDetailsModalClose() {
    if (isTxOngoing) return

    dispatch(setSelectedNFT(null))
    dispatch(setNFTDetailsModalStatus(false))
  }

  function getMintPrice() {
    if (mintPrice) {
      return formatEther(String(mintPrice)) + " ETH"
    }
    return null
  }

  return (
    <div className="journey">
      {scenario === SCENARIO_TYPE.AVATAR_WHITELIST && (
        <AvatarWhitelistScenario onMint={onFreeMint} isMintOngoing={isTxOngoing} />
      )}

      {scenario === SCENARIO_TYPE.LEGACY_WHITELIST && (
        <LegacyWhitelistScenario onMint={onFreeMint} isMintOngoing={isTxOngoing} />
      )}

      {scenario === SCENARIO_TYPE.PUBLIC && (
        <PublicAddressScenario onMint={onPaidMint} isMintOngoing={isTxOngoing} mintPrice={getMintPrice()} />
      )}

      {scenario === SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY && <EligibleForFilterOnlyScenario />}

      {shouldShowCommunityNFTs() ? <FoundSomethingElse nfts={nfts} /> : null}

      <Modal isOpen={shouldOpenSignYourWalletModal()} onRequestClose={() => {}}>
        <SignYourWallet />
      </Modal>

      <Modal isOpen={isNFTDetailsModalOpen} onRequestClose={onNFTDetailsModalClose}>
        <NFTDetails onMint={onFreeMint} isMintOngoing={isTxOngoing} onCancel={onNFTDetailsModalClose} />
      </Modal>
    </div>
  )
}

function AvatarWhitelistScenario({ onMint, isMintOngoing }) {
  const dispatch = useDispatch()
  const account = useAccount()
  // const { width } = useWindowSize()
  const { signMessageAsync } = useSignMessage()

  const step = useSelector((state) => state.user.step)
  const eligibility = useSelector((state) => state.user.eligibility)
  const accessToken = useSelector((state) => state.user.accessToken)
  const lockedUntil = useSelector((state) => state.user.lockedUntil)
  const isNFTDetailsModalOpen = useSelector((state) => state.user.isNFTDetailsModalOpen)

  const { isCountdownOver } = useCountdown(lockedUntil)

  const [isSignatureOngoing, setSignatureOngoing] = useState(false)

  const shouldOpenRaffleRulesModal = step === AVATAR_SCENARIO_STEPS.RAFFLE_RULES

  useEffect(() => {
    if (isCountdownOver) {
      dispatch(getLockedNFTs({ address: account.address }))

      if (isNFTDetailsModalOpen) {
        dispatch(setNFTDetailsModalStatus(false))
      }
    }
  }, [isCountdownOver])

  async function onRoll() {
    if (step === AVATAR_SCENARIO_STEPS.RAFFLE_OPTIONS) {
      try {
        await checkAndSwitchToSupportedNetwork()

        setSignatureOngoing(true)

        const signature = await signMessageAsync({ message: getSignatureMessage(account.address?.toLowerCase()) })

        setSignatureOngoing(false)

        const payload = {
          address: account.address,
          signature,
        }

        await dispatch(registerUser(payload))

        dispatch(rollAll({ address: account.address }))
      } catch (error) {
        setSignatureOngoing(false)
      }
    }

    if (!step) {
      dispatch(changeStep(AVATAR_SCENARIO_STEPS.RAFFLE_RULES))
    }
  }

  function onRaffleRulesModalClose() {
    dispatch(changeStep(""))
  }

  function onRaffleRuleAccept() {
    dispatch(changeStep(AVATAR_SCENARIO_STEPS.RAFFLE_OPTIONS))
  }

  // handles roll ongoing and roll success ui
  if ([AVATAR_SCENARIO_STEPS.RAFFLE_ONGOING, AVATAR_SCENARIO_STEPS.RAFFLE_SUCCESS].includes(step)) {
    return <RaffleDone onMint={onMint} isMintOngoing={isMintOngoing} />
  }

  if (step === AVATAR_SCENARIO_STEPS.COUNTDOWN_TIMEOUT) {
    return <TimeOutAutoSelection onMint={onMint} isMintOngoing={isMintOngoing} />
  }

  if (step === AVATAR_SCENARIO_STEPS.MINT_SUCCESS) {
    return <ActivatePersona />
  }

  return (
    <>
      <Eligibility scenario={SCENARIO_TYPE.AVATAR_WHITELIST} eligibility={"Whitelisted"} mintType="Raffle Mint" />

      <div className="journey-scenario">
        <ScenarioTitle scenario={SCENARIO_TYPE.AVATAR_WHITELIST} showMintLimit />

        <ChubbyCubsHorizontalList />

        <MintProgress />

        <RollOptions onRoll={onRoll} />

        {/* <Modal isOpen={isHowItWorksModalOpen} onRequestClose={() => setHowItWorksModal(false)}>
          <TransactionFailed />
        </Modal> */}

        <Modal isOpen={shouldOpenRaffleRulesModal} onRequestClose={onRaffleRulesModalClose}>
          <RaffleRules onProceed={onRaffleRuleAccept} onCancel={onRaffleRulesModalClose} />
        </Modal>

        <Modal isOpen={isSignatureOngoing} onRequestClose={() => {}}>
          <SignYourWallet />
        </Modal>
      </div>
    </>
  )
}

function LegacyWhitelistScenario({ onMint, isMintOngoing }) {
  const step = useSelector((state) => state.user.step)

  if (step === LEGACY_SCENARIO_STEPS.MINT_SUCCESS) {
    return <ActivatePersona />
  }

  return (
    <>
      <Eligibility scenario={SCENARIO_TYPE.LEGACY_WHITELIST} eligibility={"Whitelisted"} mintType="Free Mint" />

      <div className="journey-scenario">
        <ScenarioTitle scenario={SCENARIO_TYPE.LEGACY_WHITELIST} showMintLimit />

        <ChubbyCubsHorizontalList />

        <MintButton buttonName={"Mint & Activate"} onMint={onMint} isMintOngoing={isMintOngoing} />

        <MintProgress />

        <OfferHelp type="minting" />
      </div>
    </>
  )
}

function PublicAddressScenario({ onMint, isMintOngoing, mintPrice }) {
  const step = useSelector((state) => state.user.step)

  if (step === PUBLIC_SCENARIO_STEPS.MINT_SUCCESS) {
    return <ActivatePersona />
  }

  function getMintButtonLabel() {
    if (mintPrice) {
      return `Mint for ${mintPrice}`
    }
    return `Mint`
  }

  return (
    <>
      <Eligibility scenario={SCENARIO_TYPE.PUBLIC} eligibility={"Public Sale"} mintType={mintPrice} />

      <div className="journey-scenario">
        <ScenarioTitle scenario={SCENARIO_TYPE.PUBLIC} showMintLimit />

        <ChubbyCubsHorizontalList />

        <MintButton buttonName={getMintButtonLabel()} onMint={onMint} isMintOngoing={isMintOngoing} />

        <MintProgress />

        <OfferHelp type="minting" />
      </div>
    </>
  )
}

function EligibleForFilterOnlyScenario() {
  const dispatch = useDispatch()

  const step = useSelector((state) => state.user.step)

  const [isActivating, setActivating] = useState(false)

  if (step === ELIGIBLE_FOR_FILTER_ONLY_SCENARIO_STEPS.MINT_SUCCESS) {
    return <ActivatePersona />
  }

  async function onActivate() {
    setActivating(true)

    await sleepFor(3000)

    dispatch(changeStep(ELIGIBLE_FOR_FILTER_ONLY_SCENARIO_STEPS.MINT_SUCCESS))
  }

  return (
    <div className="eligible-for-filter-only">
      <Eligibility
        scenario={SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY}
        eligibility={"Supported NFT Projects"}
        mintType={`AR Filter Activation`}
      />

      <div className="journey-scenario">
        <ScenarioTitle scenario={SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY} />

        <NFTCarousel />

        <MintButton buttonName={`Activate`} onMint={onActivate} isMintOngoing={isActivating} />
      </div>
    </div>
  )
}

function RaffleDone({ onMint, isMintOngoing }) {
  const isRollOngoing = useSelector((state) => state.user.isRollOngoing)
  const isGettingLockedNFTs = useSelector((state) => state.user.isGettingLockedNFTs)

  const lockedNFTs = useSelector((state) => state.user.lockedNFTs)
  const lockedUntil = useSelector((state) => state.user.lockedUntil)

  const { remainingTime } = useCountdown(lockedUntil)

  const showSkeletonLoader = isRollOngoing || isGettingLockedNFTs

  const TEXT = {
    title: `Your One-of-a-Kind Chubby Cub Awaits`,
    subtitle: `Roll the dice to give your Chubby Cub its unique traits! Choose carefully as your selection is final once you complete the minting process.`,
  }

  return (
    <>
      <Eligibility scenario={SCENARIO_TYPE.AVATAR_WHITELIST} eligibility={"Whitelisted"} mintType="Raffle Mint" />

      <div className="raffle-done">
        <div className="raffle-done-titles-container">
          <div className="raffle-done-title">
            <p>{TEXT.title}</p>
          </div>

          <div className="raffle-done-subtitle">{TEXT.subtitle}</div>
        </div>

        <p className="raffle-done-mint-limit">Limited to 1 mint per wallet address.</p>

        <div className="raffle-done-mint-progress">
          <MintProgress />
        </div>

        <div className="raffle-done-time-remaining">
          <span className="raffle-done-time-remaining-label">Time Remaining:</span>
          <span className="raffle-done-time-remaining-duration">{remainingTime}</span>
        </div>

        <p className="raffle-done-outcome">
          The combination of Chubby Cub traits shown are arranged from the rarest overall combination to the most
          common.
        </p>

        {showSkeletonLoader && <RaffleOngoing />}

        {!showSkeletonLoader && lockedNFTs?.length > 0 ? (
          <RaffleNFTList onMint={onMint} isMintOngoing={isMintOngoing} />
        ) : null}
      </div>
    </>
  )
}

// renders UI for time-locked and user-locked edge-cases
function TimeOutAutoSelection({ onMint, isMintOngoing }) {
  const { width } = useWindowSize()

  const lockType = useSelector((state) => state.user.lockType)
  const selectedNFT = useSelector((state) => state.user.selectedNFT)

  const UI_TEXT = {
    "user-locked": {
      title: "Welcome back!",
      subtitle: `You’ve selected your traits, now it’s time to seal the deal. Kindly complete the minting process before Phase 1 concludes.`,
    },
    "time-locked": {
      title: `Time’s Up!`,
      subtitle:
        "The trait selection window has passed, but don't worry! We've selected the rarest combination of traits on your behalf.",
    },
  }

  return (
    <div className="timeout">
      <div className="timeout-titles-container">
        <div className="timeout-title">
          <p>{UI_TEXT[lockType].title}</p>
        </div>

        <p className="timeout-subtitle">{UI_TEXT[lockType].subtitle}</p>
      </div>

      <div className="timeout-nft">
        <div className="timeout-nft-image">
          <img src={getUnassignedNFTAssetURLByImageName(selectedNFT?.image)} alt="chubby-cub" />
        </div>

        <div className="timeout-nft-info">
          <div className="timeout-nft-info-title">
            {width >= 430 && <p className="timeout-nft-info-heading">Traits</p>}
            <p className="timeout-nft-info-name">{selectedNFT?.name}</p>
          </div>

          <div className="timeout-nft-info-traits">
            {selectedNFT?.attributes?.map((trait, index) => (
              <div className="timeout-nft-info-single-trait">
                <span className="timeout-nft-info-single-trait-type">{trait.trait_type}</span>
                <span className="timeout-nft-info-single-trait-value">{trait.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isMintOngoing ? (
        <PendingButton />
      ) : (
        <MintButton onMint={onMint} isMintOngoing={isMintOngoing} buttonName="Mint & Activate" />
      )}

      <div className="timeout-switch-and-mint">
        <MintProgress />
      </div>

      <OfferHelp type="minting" />
    </div>
  )
}

function RaffleNFTList({ onMint, isMintOngoing }) {
  const dispatch = useDispatch()

  const DUMMY = [
    {
      traitId: 4281,
      lockedUntil: "9999-12-31T23:59:59.000Z",
      score: 231.92,
      status: 1,
    },
    {
      traitId: 9245,
      lockedUntil: "2024-07-01T10:46:56.547Z",
      score: 218.91,
      status: 0,
    },
    {
      traitId: 6283,
      lockedUntil: "2024-07-01T10:46:56.547Z",
      score: 198.06,
      status: 0,
    },
    {
      traitId: 9104,
      lockedUntil: "2024-07-01T10:46:56.547Z",
      score: 194.3,
      status: 0,
    },
    {
      traitId: 9100,
      lockedUntil: "2024-07-01T10:46:56.547Z",
      score: 194.12,
      status: 0,
    },
    {
      traitId: 7559,
      lockedUntil: "2024-07-01T10:46:56.547Z",
      score: 157.29,
      status: 0,
    },
    {
      traitId: 5298,
      lockedUntil: "2024-07-01T10:46:56.547Z",
      score: 133.09,
      status: 0,
    },
  ]

  const lockedNFTs = useSelector((state) => state.user.lockedNFTs)

  function onNFTSelect(nft) {
    dispatch(getNFTMetadataByTraitId({ traitId: nft.traitId }))
    dispatch(setNFTDetailsModalStatus(true))
  }

  return (
    <>
      <div className="raffle-nft-list">
        {lockedNFTs?.map((nft, index) => (
          <div className="raffle-nft-list-item" key={nft.traitId} onClick={() => onNFTSelect(nft)}>
            <div className="raffle-nft-list-item-image">
              {/* <img src={nft.url} alt="chubby-cub" /> */}
              <img src={getUnassignedNFTAssetURLByTraitId(nft.traitId)} alt="" />
            </div>
            {/* <div className="raffle-nft-list-item-label">
              <span>#{nft.traitId}</span>
            </div> */}
          </div>
        ))}
      </div>
    </>
  )
}

function RaffleOngoing() {
  return (
    <div className="raffle-done-skeleton-boxes">
      <span className="raffle-done-skeleton-boxes-1" />
      <span className="raffle-done-skeleton-boxes-2" />
      <span className="raffle-done-skeleton-boxes-3" />
    </div>
  )
}

function MintProgress() {
  const { width } = useWindowSize()

  const { data: mintedNFTCount, refetch: refetchMintedNFTCount } = useReadContract({
    abi: CHUBBY_CUBS_ABI,
    address: CHUBBY_CUBS_CONTRACT_ADDRESS,
    functionName: "totalSupply",
    query: {
      refetchInterval: MINTED_NFT_COUNT_POLLING_INTERVAL,
    },
  })

  const { data: totalNFTCount } = useReadContract({
    abi: CHUBBY_CUBS_ABI,
    address: CHUBBY_CUBS_CONTRACT_ADDRESS,
    functionName: "MAX_ELEMENTS",
  })

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     refetchMintedNFTCount()
  //   }, MINTED_NFT_COUNT_POLLING_INTERVAL)
  //   return () => clearInterval(interval)
  // }, [refetchMintedNFTCount])

  function getWidthPercentage(totalSupply) {
    const totalSupplyNum = Number(totalSupply)

    if (totalSupplyNum === 0) return 0

    const percentage = (totalSupplyNum / 10000) * 100

    return percentage
  }

  return (
    <div className="mint-progress">
      <div className="mint-progress-outer">
        <div className="mint-progress-inner" style={{ width: `${getWidthPercentage(mintedNFTCount)}%` }} />
      </div>

      <div className="mint-quantity">
        <p>Total Minted</p>
        <p>
          {Number(mintedNFTCount || 0)}/{Number(totalNFTCount || 0).toLocaleString()} {width >= 430 ? "Minted" : ""}
        </p>
      </div>
    </div>
  )
}

function ScenarioTitle(props) {
  return (
    <>
      <div className="journey-titles-container">
        <div className="journey-title">
          <p dangerouslySetInnerHTML={{ __html: UI_TEXT[props.scenario].title }} />
        </div>

        <div className="journey-subtitle" dangerouslySetInnerHTML={{ __html: UI_TEXT[[props.scenario]].subtitle }} />
      </div>
      {props.showMintLimit && <p className="journey-mint-limit">Limited to 1 mint per wallet address.</p>}
    </>
  )
}

function ChubbyCubsHorizontalList() {
  const { width } = useWindowSize()

  const CUBS = [
    {
      src: Chubby1,
    },
    {
      src: Chubby2,
    },
    {
      src: Chubby3,
    },
    {
      src: Chubby4,
    },
    {
      src: Chubby5,
    },
  ].slice(0, width <= 320 ? 3 : 5)

  return (
    <>
      <div className="cubs">
        <div className="cubs-list">
          {CUBS.map((cub, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${cub.src})`,
                }}
              />
            )
          }).filter(Boolean)}
        </div>
        <div className="cubs-overlay"></div>
      </div>
    </>
  )
}

function isNFTAlreadyOwned(COLLECTIONS, OWNED) {
  // Create a set of owned collection names for faster lookup
  const ownedSet = new Set(OWNED.map((item) => item.collectionName))

  // Iterate through COLLECTIONS and add isOwned flag if the name is in the ownedSet
  return COLLECTIONS.map((collection) => {
    if (ownedSet.has(collection.collectionName)) {
      return {
        ...collection,
        isOwned: true,
      }
    } else {
      return {
        ...collection,
        isOwned: false,
      }
    }
  })
}

function FoundSomethingElse({ nfts = [] }) {
  const scenario = useSelector((state) => state.user.scenario)

  const collectionsList = isNFTAlreadyOwned(COLLECTIONS, nfts)

  const shouldShowTitleAndSubtitle = scenario !== SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY

  return (
    <>
      <div
        className={`journey-else ${
          scenario === SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY ? "eligible-for-filter-only" : ""
        }`}
      >
        {shouldShowTitleAndSubtitle ? (
          <>
            <p className="journey-else-title">Great News!</p>
            <p className="journey-else-subtitle">
              It appears you own an eligible NFT. Activate the AR filters of your eligible NFTs by minting your Chubby
              Cub now!
            </p>
          </>
        ) : null}

        <div className="journey-else-collections">
          {collectionsList.map((collection, index) => (
            <div className="journey-else-collection-item">
              <div className="journey-else-collection-item-image">
                <img
                  src={collection.image}
                  alt="nft-collection"
                  style={{
                    opacity: collection.isOwned ? 1 : 0.5,
                  }}
                />

                {collection.isOwned ? (
                  <div className="journey-else-collection-item-checked">
                    <CheckGradientIcon />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Journey
