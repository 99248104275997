import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAccount } from "wagmi"

import "./ActivatePersona.scss"


import { ReactComponent as CopyIcon } from "../../assets/svg/copy-icon.svg"
import { ReactComponent as GooglePlay } from "../../assets/svg/get-it-on-google-play.svg"
import { ReactComponent as AppStore } from "../../assets/svg/download-on-the-appstore.svg"

import useWindowSize from "../../hooks/useWindowResize"
import { shortenTxHash } from "../../utils/textUtils"
import { SCENARIO_TYPE, createDeeplink } from "../../store/slices/userSlice"

// components
import toast from "../../global/Toast/Toast"
import OfferHelp from "../OfferHelp/OfferHelp"
import NFTCarousel from "../NFTCarousel/NFTCarousel"

const UI_TEXT = {
  [SCENARIO_TYPE.AVATAR_WHITELIST]: {
    title: `Welcome to the Chubby Cubs Club!`,
    subtitle: `Your Chubby Cubs has been minted! The AR filters of your Chubby Cubs and all of your eligible NFTs have been activated as well. Please scan the provided QR code with your mobile device to begin the account creation process.`,
  },
  [SCENARIO_TYPE.LEGACY_WHITELIST]: {
    title: `Welcome to the Chubby Cubs Club!`,
    subtitle: `Now that your Chubby Cub has been minted, you’ve unlocked your exclusive NFT AR Filter! Scan the QR code below with your phone camera to create your account.`,
  },
  [SCENARIO_TYPE.PUBLIC]: {
    title: `Welcome to the Chubby Cubs Club!`,
    subtitle: `Now that your Chubby Cub has been minted, you’ve unlocked your exclusive NFT AR Filter! Scan the QR code below with your phone camera to create your account.`,
  },
  [SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY]: {
    title: `Your AR Filter Has Been Activated!`,
    subtitle: `Scan the QR code below with your phone camera to create your account.`,
  },
}
function ActivatePersona() {
  const dispatch = useDispatch()
  const account = useAccount()
  const { width } = useWindowSize()

  const scenario = useSelector((state) => state.user.scenario)
  const mintTxHash = useSelector((state) => state.user.mintTxHash)
  const selectedNFT = useSelector((state) => state.user.selectedNFT)

  const signature = useSelector((state) => state.user.signature)

  const qrcode = useSelector((state) => state.user.qrcode)

  const [isRevealed, setIsRevealed] = useState(false)

  useEffect(() => {
    // NOTE: If you send a POST request to this endpoint with the same alias, and a matching set of other POST parameters to an existing aliased link, the original will be returned to you. If it clashes and you don't specify a match, a HTTP 400 error will be returned.
    const payload = {
      branch_key: process.env.REACT_APP_BRANCH_KEY,
      data: {
        // type: 2,
        // $marketing_title: "Torum V2",
        signature,
        address: account.address,
      },
    }

    if (!qrcode) {
      dispatch(createDeeplink(payload))
    }
  }, [])

  if (!scenario) return null

  function onReveal() {
    setIsRevealed(true)
  }

  function onTxHashCopy(hash) {
    navigator.clipboard
      .writeText(hash)
      .then(() => {
        toast({
          type: "info",
          text: `Txn hash copied`,
          customConfig: {
            // the presence of toastId will prevent duplicate toasts on repeated clicks
            toastId: "tx-hash-copied",
          },
        })
      })
      .catch((error) => {})
  }

  function getTransactionHash() {
    if (!mintTxHash) return null

    return (
      <div className="activate-persona-tx-hash">
        <div className="activate-persona-tx-hash-left-section">
          <div className="activate-persona-tx-hash-label">Txn Hash :</div>
          <div className="activate-persona-tx-hash-value">&nbsp;{shortenTxHash(mintTxHash)}</div>
        </div>
        <div className="activate-persona-tx-hash-copyicon" onClick={() => onTxHashCopy(mintTxHash)}>
          <CopyIcon />
        </div>
      </div>
    )
  }

  return (
    <div className="activate-persona">
      <div className="activate-persona-titles">
        <p className="activate-persona-titles-main">{UI_TEXT[scenario].title}</p>
        <p className="activate-persona-titles-sub">{UI_TEXT[scenario].subtitle}</p>
      </div>

      {getTransactionHash()}

      <div className="activate-persona-download">
        <div className="activate-persona-download-qr">
          <div></div>
        </div>

        <div className="activate-persona-qr-privacy">
          <img src={qrcode} alt="qr-code" style={{ opacity: isRevealed ? 1 : 0.4 }} />

          {!isRevealed ? (
            <div className="activate-persona-qr-privacy-veil">
              <p className="activate-persona-qr-privacy-disclaimer">
                <span className="activate-persona-qr-privacy-disclaimer-title">Never disclose your QR code!</span>
                <br />
                <span className="activate-persona-qr-privacy-disclaimer-subtitle">
                  This will allow scanners to access/create a Torum 2.0 account with your NFT.
                </span>
              </p>
              <p className="activate-persona-qr-privacy-reveal" onClick={onReveal}>
                Tap to Reveal
              </p>
            </div>
          ) : null}
        </div>

        <div className="activate-persona-download-store">
          <p className="activate-persona-download-store-cue">Download the app right away and follow the cues.</p>

          {width <= 744 && <OfferHelp type="onboarding" />}

          <div className="activate-persona-download-store-icons">
            <GooglePlay />
            <AppStore />
          </div>

          <p className="activate-persona-download-store-unique">
            The download links are unique, you will be redirected accordingly.
          </p>

          {width > 744 && <OfferHelp type="onboarding" />}
        </div>
      </div>

      <div className="activate-persona-nft-preview-container">
        <NFTCarousel />
        {/* <NFTAndCollectionImagePair
          nftImageSrc={selectedNFT?.image}
          nftCommunityImageSrc={ChubbyCubCollectionImage}
          nftName={selectedNFT?.name}
        /> */}
      </div>
    </div>
  )
}

export default ActivatePersona
