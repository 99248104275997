import { ReactComponent as HomeIcon } from "../assets/svg/home-navbar-icon.svg"
import { ReactComponent as HomeIconActive } from "../assets/svg/home-navbar-icon-active.svg"
import { ReactComponent as CollectionIcon } from "../assets/svg/collection-navbar-icon.svg"
import { ReactComponent as CollectionIconActive } from "../assets/svg/collection-navbar-icon-active.svg"
import { ReactComponent as LabIcon } from "../assets/svg/lab-navbar-icon.svg"
import { ReactComponent as LabIconActive } from "../assets/svg/lab-navbar-icon-active.svg"
import { ReactComponent as XtmIcon } from "../assets/svg/xtm-navbar-icon.svg"
import { ReactComponent as XtmIconActive } from "../assets/svg/xtm-navbar-icon-active.svg"
import { ReactComponent as TeamIcon } from "../assets/svg/team-navbar-icon.svg"
import { ReactComponent as TeamIconActive } from "../assets/svg/team-navbar-icon-active.svg"

export const ROUTES = {
  HOME: {
    path: "/",
    label: "Home",
    mixpanelEventName: "LP_Navbar_Home",
    inactiveIcon: <HomeIcon />,
    activeIcon: <HomeIconActive />,
  },
  // BLOGS: {
  //   path: "/blogs",
  //   label: "Blogs",
  // },
  NFT_COLLECTION: {
    path: "/nft-collection",
    label: "NFT Collection",
    mixpanelEventName: "LP_Navbar_NFTCollection",
    inactiveIcon: <CollectionIcon />,
    activeIcon: <CollectionIconActive />,
  },
  CREATORS_LAB: {
    path: "/creators-lab",
    label: "Creators Lab",
    mixpanelEventName: "LP_Navbar_CreatorsLab",
    inactiveIcon: <LabIcon />,
    activeIcon: <LabIconActive />,
  },
  XTM: {
    path: "/xtm",
    label: "XTM",
    mixpanelEventName: "LP_Navbar_XTM",
    inactiveIcon: <XtmIcon />,
    activeIcon: <XtmIconActive />,
  },
  TEAM: {
    path: "/team",
    label: "Team",
    mixpanelEventName: "LP_Navbar_Team",
    inactiveIcon: <TeamIcon />,
    activeIcon: <TeamIconActive />,
  },
  // APP: {
  //   path: "/app",
  //   label: "App",
  // },
  // FAQ: {
  //   path: "/faqs",
  //   label: "FAQs",
  // },
  DASHBOARD: {
    path: "/dashboard",
    label: "Dashboard",
    isAuthenticated: true,
  },
  LETTER: {
    path: "/letter",
    label: "Letter",
  },
}
