import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWeb3Modal } from "@web3modal/wagmi/react"
import { useAccount, useReadContract, useSignMessage } from "wagmi"
import { useLocation } from "react-router-dom"
import { ethers } from "ethers"

import NftCollectionsBg from "../../assets/nft-collections-hero-bg.png"
import ChubbyCubAvatar from "../../assets/chubby-cub-avatar.png"
import ChubbyCubLogoSm from "../../assets/svg/chubby-cub-logo-sm.svg"
import ChubbyCubLogo from "../../assets/svg/chubby-cub-logo.svg"
import ChubbyCubVid from "../../assets/cubs-showcase-1360.mp4"
import ChubbyCubVidSmall from "../../assets/cubs-showcase-1360-responsive.mp4"
import ChubbyCubVidWebM from "../../assets/cubs-showcase-1360.webm"
import ChubbyCubVidSmallWebM from "../../assets/cubs-showcase-1360-responsive.webm"
import BlastIcon from "../../assets/svg/blast-icon.svg"
import { ReactComponent as GetStartedIcon1 } from "../../assets/svg/ownership-perk-1.svg"
import { ReactComponent as GetStartedIcon2 } from "../../assets/svg/ownership-perk-2.svg"
import { ReactComponent as GetStartedIcon3 } from "../../assets/svg/ownership-perk-3.svg"
import { ReactComponent as GetStartedIcon4 } from "../../assets/svg/ownership-perk-4.svg"
import { ReactComponent as OpenSeaLogo } from "../../assets/svg/opensea-logo.svg"
import { ReactComponent as SupplyIcon } from "../../assets/svg/new-nft-supply-icon.svg"
import { ReactComponent as ReserveIcon } from "../../assets/svg/new-nft-reserve-icon.svg"
import { ReactComponent as WhitelistIcon } from "../../assets/svg/new-nft-whitelist-icon.svg"
import { ReactComponent as RoyaltiesIcon } from "../../assets/svg/new-nft-royalties-icon.svg"
import { ReactComponent as GlobeIcon } from "../../assets/svg/new-nft-globe-icon.svg"

// components
import Journey from "../home/Journey/Journey"
import Button from "../../components/Button/Button"
import { Card, CardWrapper } from "../../components/Card"

import "./styles.scss"

import { COLLECTIONS } from "../../constants"
import { CHUBBY_CUBS_CONTRACT_ADDRESS } from "../../web3/tokens"
import CHUBBY_CUBS_ABI from "../../contracts/CHUBBY_CUBS_ABI.json"
import {
  AVATAR_SCENARIO_STEPS,
  LEGACY_SCENARIO_STEPS,
  NFT_TRAITS_STATUS,
  PUBLIC_SCENARIO_STEPS,
  SCENARIO_TYPE,
  changeScenario,
  changeStep,
  getLockedNFTs,
  getNFTMetadataByTokenId,
  getNFTMetadataByTraitId,
  getScenario,
  registerUser,
  setMintedTokenId,
} from "../../store/slices/userSlice"
import { getMetadataBaseURIFromContract, getOwnedTokenIdListFromContract } from "../../utils/getContractData"
import { Mixpanel } from "../../utils/mixpanel"
import { LP_NFTCollection_JoinWaitlist, other_source } from "../../utils/mixpanel/mixpanelActionTypes"
import { getSignatureMessage } from "../../utils/signature"

function NFTCollection() {
  const dispatch = useDispatch()
  const { open } = useWeb3Modal()
  const account = useAccount()

  const location = useLocation()
  const { signMessageAsync } = useSignMessage()

  const queryParams = new URLSearchParams(location.search)

  const debugMode = queryParams.get("debug") === "true"

  const isMinted = useSelector((state) => state.user.isMinted)
  const isARFilterClaimed = useSelector((state) => state.user.isARFilterClaimed)
  const isEligibleToClaimARFilter = useSelector((state) => state.user.isEligibleToClaimARFilter)
  const eligibility = useSelector((state) => state.user.eligibility)
  const scenario = useSelector((state) => state.user.scenario)
  const lockedNFTs = useSelector((state) => state.user.lockedNFTs)

  const [totalSupply, setTotalSupply] = useState(0)

  const [currentImage, setCurrentImage] = useState(window.innerWidth < 769 ? ChubbyCubVidSmall : ChubbyCubVid)
  const [currentImageWebm, setCurrentImageWebM] = useState(
    window.innerWidth < 769 ? ChubbyCubVidSmallWebM : ChubbyCubVidWebM
  )

  const updateImage = () => {
    if (window.innerWidth < 768) {
      setCurrentImage(ChubbyCubVidSmall)
      setCurrentImageWebM(ChubbyCubVidSmallWebM)
    } else {
      setCurrentImage(ChubbyCubVid)
      setCurrentImageWebM(ChubbyCubVidWebM)
    }
  }

  useEffect(() => {
    // Update the image initially
    updateImage()

    // Add the event listener for window resize
    window.addEventListener("resize", updateImage)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateImage)
    }
  }, [])

  // useEffect(() => {
  //   // const provider = new ethers.AnkrProvider(
  //   //   "mainnet",
  //   //   "e9ff080bbdc000bf76148b0800c266f8cf89382ec38135e0e18f28205c762e68"
  //   // )
  //
  //   // TODO: Add production mainnet network name
  //   const provider = new ethers.InfuraProvider(process.env.REACT_APP_ENV === "production" ? "" : "sepolia")
  //
  //   const contract = new ethers.Contract(CHUBBY_CUBS_CONTRACT_ADDRESS, CHUBBY_CUBS_ABI, provider)
  //
  //   const getTotalSupplyFromContract = async () => {
  //     try {
  //       const totalSupply = await contract.totalSupply()
  //
  //       setTotalSupply(String(totalSupply))
  //     } catch (error) {
  //       console.error("Error reading data from contract:", error)
  //     }
  //   }
  //
  //   getTotalSupplyFromContract()
  // }, [])

  // useEffect(() => {
  //   /**
  //    * Edge Case: In Avatar scenario, if the user did not mint before the countdown finishes,
  //    * then when they come back later, the eligibility api gives error saying 'User can only mint once'.
  //    * Since the eligibility api doesn't give ideal response, we depend on the lockedNFT data to determine the scenario
  //    * When lockedNFT list === 1, it means the scenario is AVATAR_WHITELIST and the countdown has timed out!
  //    *
  //    */
  //   if (lockedNFTs?.length === 1) {
  //     dispatch(changeScenario(SCENARIO_TYPE.AVATAR_WHITELIST))

  //     // when the nft is time locked or user locked, redirect user to timeout ui
  //     if ([NFT_TRAITS_STATUS.TIME_LOCKED, NFT_TRAITS_STATUS.USER_LOCKED].includes(lockedNFTs[0]?.status)) {
  //       dispatch(getNFTMetadataByTraitId({ traitId: lockedNFTs[0]?.traitId }))
  //       dispatch(changeStep(AVATAR_SCENARIO_STEPS.COUNTDOWN_TIMEOUT))
  //     }
  //   }
  // }, [lockedNFTs])

  // const { data: ownershipData } = useReadContract({
  //   abi: CHUBBY_CUBS_ABI,
  //   address: CHUBBY_CUBS_CONTRACT_ADDRESS,
  //   functionName: "walletOfOwner",
  //   args: [account.address],
  // })

  // const { data: canMintOrClaim } = useReadContract({
  //   abi: CHUBBY_CUBS_ABI,
  //   address: CHUBBY_CUBS_CONTRACT_ADDRESS,
  //   functionName: "canMintOrClaim",
  // })

  // if (process.env.REACT_APP_ENV !== "production") {
  //   console.log(ownershipData, "ownershipData")
  //   // console.log(canMintOrClaim, "canMintOrClaim")
  //   console.log(scenario, "scenario")
  // }

  /**
   * Note: If a user is re-visiting the app after minting,
   * this effect will identify it and navigate the user to final screen
   */
  // useEffect(() => {
  //   if (ownershipData?.length > 0) {
  //     // TODO: REMOVE AFTER DISABLING CONTRACT CONDITIONS OF MINT LIMIT
  //     // Temporarily added for QA. The most recently minted token will be at the last index.
  //     // The newly minted token will be seen in the final screen.
  //     const tokenId = String(ownershipData?.[ownershipData?.length - 1])
  //
  {
    /*    // TODO: ENABLE THIS AFTER REMOVING ABOVE.*/
  }
  {
    /*    // const tokenId = String(ownershipData?.[0])*/
  }
  //
  //     // if (tokenId) {
  //     //   dispatch(setMintedTokenId(tokenId))
  //     //   dispatch(getNFTMetadataByTokenId({ tokenId }))
  //     // }
  //
  //     // user already owns ChubbyCubs
  //     // skip journey and navigate them to final QR code step
  //     // if (scenario === SCENARIO_TYPE.AVATAR_WHITELIST) {
  //     //   dispatch(changeStep(AVATAR_SCENARIO_STEPS.MINT_SUCCESS))
  //     // }
  //
  //     // if (scenario === SCENARIO_TYPE.LEGACY_WHITELIST) {
  //     //   dispatch(changeStep(LEGACY_SCENARIO_STEPS.MINT_SUCCESS))
  //     // }
  //
  //     // if (scenario === SCENARIO_TYPE.PUBLIC) {
  //     //   dispatch(changeStep(PUBLIC_SCENARIO_STEPS.MINT_SUCCESS))
  //     // }
  //   }
  // }, [ownershipData, scenario])

  // if (scenario) {
  //   return <Journey />
  // }

  async function registerIfNeeded() {
    try {
      const signature = await signMessageAsync({ message: getSignatureMessage(account.address?.toLowerCase()) })

      const payload = {
        address: account.address,
        signature,
      }

      await dispatch(registerUser(payload))

      dispatch(getLockedNFTs({ address: account.address }))
    } catch (error) {}
  }

  function getMintButtonLabel() {
    if (!account.address) return "Connect"

    if (isMinted) {
      return "Minted"
    }

    // TODO: Is the label different for different scenarios ?
    return "Claim Now" // "Mint Now"
  }

  async function onMintNowButtonClick() {
    if (!account.address) {
      open()
    }

    // TODO: enable these conditions
    // if (isMinted) return

    // if not eligible to mint, return;

    /**
     * Edge Case: When the timer runs out, the nft is time-locked.
     * If the user disconnects the wallet and comes back later, we need to identify such user.
     * The info about time-locked nft is only received from fetchLockedTraits api which is authenticated api.
     * So, here we check the number of chances and userType and based on that, we call register api.
     */

    if (eligibility?.userType === 0 && eligibility?.numberOfChances === 0) {
      await registerIfNeeded()
    }

    // allow click only if the user is eligible and has not already minted
    dispatch(changeScenario(getScenario(eligibility)))
  }

  function onLoopMeInClick() {
    if (!account.address) {
      open()
    }

    if (isEligibleToClaimARFilter) {
      dispatch(changeScenario(SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY))
    }
  }

  function getARFilterClaimButtonLabel() {
    if (!account.address) return "Connect"

    if (!isARFilterClaimed) {
      if (isEligibleToClaimARFilter) {
        return "Claim Now"
      }
    }

    return "Not Eligible"
  }

  // if (scenario) {
  //   // return <NFTCarousel />
  //   return <Journey />
  // }

  return (
    <div className="lp lp-nft-container">
      <div className="lp-nft-hero">
        <video autoPlay="autoplay" playsInline={true} loop={true} muted={true} className="chubbycub-vid">
          <source src={currentImageWebm} type="video/webm" />
          {/* note: webm video fails to play on Safari -> fallback to mp4 */}
          <source src={currentImage} type="video/mp4" />
        </video>
      </div>

      <section className="lp-section">
        <div className="lp-nft-info-container">
          <div className="lp-nft-info-list">
            {[
              {
                icon: SupplyIcon,
                title: "10,000",
                description: "Supply",
              },
              {
                icon: ReserveIcon,
                title: "1,000",
                description: "Reserve",
              },
              {
                icon: WhitelistIcon,
                title: "2,000",
                description: "Whitelist",
              },
              {
                icon: GlobeIcon,
                title: "7,000",
                description: "Public",
              },
              {
                icon: RoyaltiesIcon,
                title: "1%",
                description: "Royalties",
              },
            ].map(({ title, description, icon: Icon }, i) => {
              return (
                <div key={i} className="lp-nft-info-list-item">
                  <Icon />
                  <div className="lp-nft-info-list-text">
                    <p className="text text-bold">{title}</p>
                    <p className="text text-secondary">{description}</p>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="lp-nft-info-cta-container">
            <div className="lp-nft-info-cta-btn-wrapper">
              <img src={BlastIcon} className="blast-icon" alt="blast-icon" />

              <div className="lp-nft-info-chain-wrapper">
                <p className="text-bold text-secondary">ERC-721</p>
                <p className="text-secondary">Blast</p>
              </div>
            </div>
            {/* <Button className="button button-primary button-rounded">Stay Tuned</Button> */}
            {/* <Button
              className="button button-primary button-rounded"
              onClick={onMintNowButtonClick}
              // disabled={isMinted}
            >
              {getMintButtonLabel()}
            </Button> */}
          </div>
        </div>

        <div className="lp-nft-about-container">
          <div className="lp-nft-about-paragraphs">
            <p className="text">
              Crash-landing from a distant corner of the digital cosmos, the Chubby Cubs have found their forever home
              in Torum V2—and so can you! These adorable creatures are your key to unlocking the gates of Torum V2 as
              each Chubby Cub NFT comes with a free AR Face Filter, sculpted meticulously to resemble your unique Chubby
              Cub.
            </p>
            <p className="text">
              Are you ready to experience the magic of AR and bring your Chubby Cub NFT to life? Adopt one now and
              prepare yourself for maximum cuteness, maximum fun!
            </p>
          </div>
          {/*
          <div className="lp-nft-about-progress-bar">
            <div className="progress-bar">
              <div className="progress-bar-track">
                <div className="progress-bar-status" style={{ width: getWidthPercentage(totalSupply) }}></div>
              </div>
              <div className="progress-bar-label">
                <p>Total Minted</p>
                <p>{totalSupply}/10,000 Minted</p>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section className="lp-section">
        <div className="lp-section-header">
          <h1 className="lp-heading">Already Own an NFT From These Collections?</h1>
          <p className="text">Great! You'll get to claim an AR Face Filter of your NFT once Torum V2 goes live.</p>
        </div>

        <div className="lp-nft-invite-container">
          <div className="lp-nft-invite-group">
            {COLLECTIONS.map((item, _i) => {
              return (
                <a
                  onClick={() => {
                    Mixpanel.track(`${item.mixpanelEventName}`)
                  }}
                  href={item.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <div className="lp-nft-invite-group-item">
                    <img src={item.image} alt="nft-collection-avatar" />
                    <OpenSeaLogo />
                  </div>
                </a>
              )
            })}
            <div className="lp-nft-invite-group-item">
              <img src={ChubbyCubAvatar} alt="nft-collection-avatar" />
              {/* <OpenSeaLogo /> */}
            </div>
          </div>

          {/* <div className="lp-nft-invite-cta">
            <Button className="button button-primary" onClick={onLoopMeInClick}>
              {getARFilterClaimButtonLabel()}
            </Button>
          </div> */}

          <a
            onClick={() => {
              Mixpanel.track(LP_NFTCollection_JoinWaitlist)
            }}
            href="https://bsu5ua93efj.typeform.com/to/CqTXTybp"
            target="_blank"
            className="lp-nft-invite-cta"
          >
            <Button className="button button-primary">Join Waitlist</Button>
          </a>
        </div>
        {/*
        <div className="lp-section-footer">
          <p className="text">
            We invite you to join our Reborn journey, where NFTs unlock new realms of creativity and community
            engagement. Together, we're not just collecting digital art; we're pioneering a movement where each NFT is a
            vibrant part of our shared digital future.
          </p>
          <p className="text text-bold">
            Welcome to the next evolution of NFTs with Torum V2—where your digital collectibles truly come to life.
          </p>
        </div> */}
      </section>

      <section className="lp-section lp-nft-ownership-perks-section">
        <div className="lp-section-header">
          <h1 className="lp-heading">Perks of Owning an NFT on Torum V2</h1>
          <p className="text">You're no longer flipping JPEGs, you're crafting a persona and creating memories.</p>
        </div>

        <CardWrapper>
          <Card
            icon={GetStartedIcon1}
            title="Web3 Identity"
            description="Complete ownership of your pseudo-anonymous identity."
          />
          <Card
            icon={GetStartedIcon2}
            title="NFT 3D AR Filter"
            description="Meticulously sculpted to resemble your unique NFT"
          />
          <Card
            icon={GetStartedIcon3}
            title="Exclusive Access"
            description="Access Torum V2 and
            pioneer the new era of SocialFi."
          />
          <Card
            icon={GetStartedIcon4}
            title="Community Events"
            description="Make memories, reap rewards,  build meaningful connections!"
          />
        </CardWrapper>
      </section>
    </div>
  )
}

export default NFTCollection
