import React from "react"

import NftStory1 from "../../../assets/nft-story-1.png"
import NftStory2 from "../../../assets/nft-story-2.png"
import NftStory3 from "../../../assets/nft-story-3.png"
import NftStory4 from "../../../assets/nft-story-4.png"
import Star from "../../../assets/svg/star-icon-home.svg"

import { ReactComponent as NftStory1Icon } from "../../../assets/svg/nft-story-1-icon.svg"
import { ReactComponent as NftStory2Icon } from "../../../assets/svg/nft-story-2-icon.svg"
import { ReactComponent as NftStory3Icon } from "../../../assets/svg/nft-story-3-icon.svg"
import { ReactComponent as NftStory4Icon } from "../../../assets/svg/nft-story-4-icon.svg"

import VideoShowcase from "../../../assets/loop-test.mp4"
import VideoShowcase2 from "../../../assets/loop-test.webm"

import Carousel from "../../../components/Carousel/Carousel"

import "./styles.scss"

const nftStoryContext = [
  {
    imageUrl: NftStory3,
    title: [
      " Connect With Digital Buddies.",
      // <>
      //   <span className="text-orange">Connect With Digital Buddies.</span>
      // </>
    ],
    description: [
      "Your NFT isn't just a digital asset; it's a platform for genuine friendships in the Web3 world. Move beyond mere display and dive into authentic conversations, forging bonds and sharing moments.",
      "Stand out by truly engaging, making your NFT the bridge to meaningful interactions.",
    ],
    paginationIcon: NftStory3Icon,
  },
  {
    imageUrl: NftStory2,
    title: [
      " Tell Your Story with Tales.",
      // <>
      //   <span className="text-purple">Tell Your Story with Tales.</span>
      // </>
    ],
    description: [
      "Whether you envision your Pudgy Penguin as a foodie vlogger, your Sappy Seal as the next big song seal. Or hey, maybe your Cool Cat just loves to shit in the toilet — no judgment here! ",
    ],
    paginationIcon: NftStory2Icon,
  },
  {
    imageUrl: NftStory1,
    title: [
      " Craft Your Digital Identity.",
      // <>
      //   <span className="text-green">Craft Your Digital Identity.</span>
      // </>
    ],
    description: [
      "Unleash your NFT avatar beyond boring poses. Let it laugh, talk, and even cry. Make it your Web3 alter ego — your very own mini-me!",
      "Each moment and post creates a unique legacy, forging connections and shared memories.",
    ],
    paginationIcon: NftStory1Icon,
  },
  {
    imageUrl: NftStory4,
    title: [
      " It’s More Than Just a JPEG.",
      // <>
      //   <span className="text-blue">It’s More Than Just a JPEG</span>.
      // </>
    ],
    description: [
      "Own or become? Elevate your NFT game. Don't just possess an NFT; bring it to life. Transform that cool pic into a dynamic digital persona and let it shine in the Web3 world. ",
      "Dive in, craft its story, and stand out in the NFT space.",
    ],
    paginationIcon: NftStory4Icon,
  },
]

function YourNFT() {
  return (
    <section className="lp-your-nft-container">
      <div className="lp-your-nft-container-bg">
        <span className="lp-your-nft-container-bg-icon" />
        <span className="lp-your-nft-container-bg-icon" />
        <span className="lp-your-nft-container-bg-icon" />
      </div>

      <div className="lp-section">
        <div className="lp-section-wrapper">
          <div className="lp-section-header">
            <h1 className="lp-heading">
              Your<span className="text-nft"> NFT </span>: <br /> Your <span className="text-story">Story </span>
            </h1>
            <p className="text">Create and share your story on our SocialFi platform.</p>
          </div>

          <div className="lp-section-vid">
            <video autoPlay="autoplay" playsInline={true} loop={true} muted={true}>
              <source src={VideoShowcase2} type="video/webm" />
              {/* note: webm video fails to play on Safari -> fallback to mp4 */}
              <source src={VideoShowcase} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="lp-nft-story-main">
          <div className="lp-nft-story-bg-outline"></div>
          {nftStoryContext.map((story, index) => (
            <div className={`lp-nft-story-context ${index % 2 !== 0 ? "reverse" : ""}`}>
              <img src={story.imageUrl} alt="test" className="story-img" />
              <div className="title-desc-wrapper">
                <div className="title-wrapper">
                  <img src={Star} alt="star" className="story-star" />
                  <p className="title">{story.title}</p>
                </div>
                <p className="desc">
                  {story.description.map((desc, i) => (
                    <span key={i}>
                      {desc}
                      <br />
                    </span>
                  ))}
                </p>
                <div className="outline-one"></div>
                <div className="outline-two"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default YourNFT
{
  /* <Carousel list={nftStoryContext} /> */
}
